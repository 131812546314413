import { ModelTrainingExample } from "@zall-bot/types";
import { MODEL_MESSAGE_ROLE_RECORD } from "../consts/MODEL_MESSAGE_ROLE_RECORD";
import { MODEL_NEW } from "../consts/MODEL_NEW";

export const toModelTrainingExamplesString = (
  trainingExamples: ModelTrainingExample[]
) => {
  const rows: string[] = [];

  trainingExamples.forEach((trainingExample, index) => {
    trainingExample.messages.forEach((message) => {
      const recordValue = MODEL_MESSAGE_ROLE_RECORD[message.role];
      rows.push(`${recordValue.short}: ${message.content}`);
    });

    if (index !== trainingExamples.length - 1) {
      rows.push(MODEL_NEW);
    }
  });

  return rows.join("\n");
};
