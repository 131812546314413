import { Paper, Stack, Typography } from "@mui/material";
import { TalentConfig } from "@zall-bot/types";
import { FC } from "react";
import { useEarningFrames } from "../../Creator/hooks/useEarningFrames";
import { CreatorEarningsView } from "../../Creator/views/CreatorEarningsView";

interface Props {
  talentConfig: TalentConfig;
}

export const TalentAnalyticsView: FC<Props> = (props) => {
  const { talentConfig } = props;
  const [earningFrames, isLoading] = useEarningFrames(talentConfig.id);

  return (
    <Stack spacing={3}>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">Analytics</Typography>
        <Stack>
          <Typography variant="ah6" color="text.secondary">
            Chats
          </Typography>
          <Typography variant="ah3">{talentConfig.chatCount}</Typography>
        </Stack>
      </Stack>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">Earnings</Typography>
        <CreatorEarningsView
          talentConfig={talentConfig}
          earningFrames={earningFrames}
          isLoading={isLoading}
        />
      </Stack>
    </Stack>
  );
};
