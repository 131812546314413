import { EarningFrame } from "@zall-bot/types";
import { Timestamp } from "firebase/firestore";
import { DateTime, Interval } from "luxon";

interface Params {
  earningFrames: EarningFrame[];
  fromDate: DateTime | null;
  toDate: DateTime | null;
}

export function normalizeEarningFrames(params: Params) {
  const { earningFrames, fromDate, toDate } = params;

  if (!fromDate || !toDate) return [];

  return Interval.fromDateTimes(fromDate, toDate.plus({ days: 1 }))
    .splitBy({ days: 1 })
    .map<EarningFrame>((interval) => {
      const intervalStart = interval.start || DateTime.local();

      const frame = earningFrames.find(
        (f) => f.id === intervalStart.toFormat("yyyy-MM-dd")
      );

      if (frame) return frame;

      return {
        id: intervalStart.toFormat("yyyy-MM-dd"),
        range: "DAY",
        currency: "USD",
        startedAt: Timestamp.fromMillis(intervalStart.toMillis()),
        talentId: "INVALID_TALENT_ID",
        totalGrossAmount: 0,
        totalNetAmount: 0,
      } satisfies EarningFrame;
    });
}
