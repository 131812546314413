import {
    FirebaseFilter,
    isModelVersion,
    ModelVersion,
} from "@zall-bot/types";
import { query, where } from "firebase/firestore";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { useValidCollectionData } from "../../Firebase/hooks/useValidCollectionData";

export function useModelVersions(
  modelId?: string,
  filters?: FirebaseFilter<ModelVersion>[]
) {
  return useValidCollectionData(
    modelId
      ? query(
          getCollectionRef(`models/${modelId}/versions`),
          ...(filters ? filters.map((filter) => where(...filter)) : [])
        )
      : null,
    isModelVersion
  );
}
