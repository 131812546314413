import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AppConfigPage } from "../../AppConfig/views/AppConfigPage";
import { ChatPage } from "../../Chat/views/ChatPage";
import { ChatPromptDialog } from "../../Chat/views/ChatPromptDialog";
import { ChatsPage } from "../../Chat/views/ChatsPage";
import { useIsAdminInterface } from "../../Common/hooks/useIsAdminInterface";
import { ModelCreatePage } from "../../Model/views/ModelCreatePage";
import { ModelPage } from "../../Model/views/ModelPage";
import { ModelsPage } from "../../Model/views/ModelsPage";
import { TalentCreatePage } from "../../Talent/views/TalentCreatePage";
import { TalentPage } from "../../Talent/views/TalentPage";
import { TalentsPage } from "../../Talent/views/TalentsPage";
import { MainLayout } from "./MainLayout";

export const MainRoutes: FC = () => {
  const isAdmin = useIsAdminInterface();

  return (
    <>
      <Routes>
        {isAdmin && (
          <Route element={<MainLayout />}>
            <Route index element={<Navigate replace to="/talents" />} />
            <Route path="app" element={<AppConfigPage />} />
            <Route path="talents" element={<TalentsPage />}>
              <Route path="create" element={<TalentCreatePage />} />
              <Route path=":talentId" element={<TalentPage />} />
            </Route>
            <Route path="chats" element={<ChatsPage />}>
              <Route path=":chatId" element={<ChatPage />} />
            </Route>
            <Route path="models" element={<ModelsPage />}>
              <Route path="create" element={<ModelCreatePage />} />
              <Route path=":modelId" element={<ModelPage />} />
            </Route>
          </Route>
        )}
        {/* {!isAdmin && (
          <Route element={<CreatorLayout />}>
            <Route index element={<CreatorDashboardPage />} />
            <Route path="referrals" element={<CreatorReferralPage />} />
            <Route path="earnings" element={<CreatorEarningsPage />} />
            <Route path="settings">
              <Route index element={<CreatorSettingsPage />} />
              <Route path="profile" element={<CreatorSettingsProfilePage />} />
              <Route path="gallery" element={<CreatorSettingsGalleryPage />} />
              <Route
                path="monetisation"
                element={<CreatorSettingsMonetizationPage />}
              />
            </Route>
          </Route>
        )} */}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
      <ChatPromptDialog />
    </>
  );
};
