import { FC } from "react";
import toast from "react-hot-toast";
import { useIsAdminInterface } from "../../Common/hooks/useIsAdminInterface";
import { ErrorPage } from "../../Common/views/ErrorPage";
import { LoadingPage } from "../../Common/views/LoadingPage";
import { useMyUser } from "../../User/hooks/useMyUser";
import { useMainInit } from "../hooks/useMainInit";
import { MainLoggedOutRoutes } from "./MainLoggedOutRoutes";
import { MainRoutes } from "./MainRoutes";

export const MainContentView: FC = () => {
  const { isInitializing, error } = useMainInit();
  const isLoggedIn = useMyUser((state) => !!state.user);
  const isAdmin = useIsAdminInterface();

  if (isInitializing) return <LoadingPage />;
  if (error) return <ErrorPage error={error} />;

  if (!isLoggedIn) {
    return <MainLoggedOutRoutes />;
  }

  if (!isAdmin) {
    toast.error("You are not authorized to access this page.");
    return <MainLoggedOutRoutes />;
  }

  return <MainRoutes />;
};
