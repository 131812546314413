import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import CloudIcon from "@mui/icons-material/Cloud";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import StarIcon from "@mui/icons-material/Star";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
} from "@mui/material";
import { FC } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { useLocalStorage } from "../../Common/hooks/useLocalStorage";
import { auth } from "../../Firebase/consts/FIREBASE";
import { MAIN_SIDEBAR_WIDTH } from "../consts/MAIN_SIDEBAR_WIDTH";

export const MainSidebarView: FC = () => {
  const navigate = useNavigate();

  return (
    <SDrawer anchor="left" variant="persistent" open>
      <SList>
        <ListItemButton to="/app" component={NavLink}>
          <ListItemIcon>
            <CloudIcon />
          </ListItemIcon>
          <ListItemText primary="App" />
        </ListItemButton>
        <ListItemButton to="/talents" component={NavLink}>
          <ListItemIcon>
            <StarIcon />
          </ListItemIcon>
          <ListItemText primary="Talents" />
        </ListItemButton>
        <ListItemButton to="/chats" component={NavLink}>
          <ListItemIcon>
            <ChatBubbleIcon />
          </ListItemIcon>
          <ListItemText primary="Chats" />
        </ListItemButton>
        <ListItemButton to="/models" component={NavLink}>
          <ListItemIcon>
            <LightbulbIcon />
          </ListItemIcon>
          <ListItemText primary="Models" />
        </ListItemButton>
      </SList>
      <Stack flex={1} />
      <SList>
        <ListItemButton
          onClick={() => {
            navigate("/");
          }}
        >
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="Talent UI" />
        </ListItemButton>
        {ENVIRONMENT.nodeEnv === "development" && (
          <ListItemButton
            component="a"
            target="_blank"
            href="http://localhost:4000"
          >
            <ListItemIcon>
              <WhatshotIcon />
            </ListItemIcon>
            <ListItemText primary="Emulator" />
          </ListItemButton>
        )}
        <ListItemButton onClick={() => void auth.signOut()}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </SList>
    </SDrawer>
  );
};

const SDrawer = styled(Drawer)(() => ({
  width: `${MAIN_SIDEBAR_WIDTH}px`,
  ".MuiDrawer-paper": {
    border: "none",
    width: `${MAIN_SIDEBAR_WIDTH}px`,
    boxSizing: "border-box",
  },
}));

const SList = styled(List)(({ theme }) => ({
  "& .MuiListItemButton-root": {
    "&.active": {
      color: theme.palette.primary.main,
      ".MuiListItemIcon-root": {
        color: theme.palette.primary.main,
      },
    },
  },
  "& .MuiListItemIcon-root": {
    minWidth: 38,
  },
}));
