import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { FirebaseAuthEmailLinkDialog } from "../../Firebase/views/FirebaseAuthEmailLinkDialog";
import { FirebaseAuthSignInPage } from "../../Firebase/views/FirebaseAuthSignInPage";

export const MainLoggedOutRoutes: FC = () => {
  return (
    <>
      <Routes>
        <Route path="sign-in" element={<FirebaseAuthSignInPage />} />
        <Route path="*" element={<Navigate replace to="/sign-in" />} />
      </Routes>
      <FirebaseAuthEmailLinkDialog />
    </>
  );
};
