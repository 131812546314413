import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
    Button,
    FormControl,
    InputAdornment,
    InputLabel,
    ListItemButton,
    MenuItem,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import { Chat, FirebaseFilter } from "@zall-bot/types";
import { FC, useState } from "react";
import { NavLink } from "react-router-dom";
import { useChats } from "../../Chat/hooks/useChats";
import { toLocaleDateTimeString } from "../../Common/helpers/toLocaleDateTimeString";
import { ErrorView } from "../../Common/views/ErrorView";
import { MainSecondarySidebarList } from "../../Main/views/MainSecondarySidebarList";
import { MainSecondarySidebarPage } from "../../Main/views/MainSecondarySidebarPage";

const OPTIONS: { label: string; filter: FirebaseFilter<Chat> }[] = [
  {
    filter: ["hasUserInteraction", "==", true],
    label: "With User Interaction",
  },
  {
    filter: ["hasUserInteractionWithNonAnonymousUser", "==", true],
    label: "With Logged In User",
  },
  {
    filter: ["hasUserInteractionWithPaidCredits", "==", true],
    label: "With Purchase",
  },
  {
    filter: ["hasUserInteractionWithManager", "==", true],
    label: "With Talent Manager",
  },
];

export const ChatsPage: FC = () => {
  const [optionIndex, setOptionIndex] = useState(2);
  const [chats, , error, controller] = useChats([OPTIONS[optionIndex].filter]);

  return (
    <MainSecondarySidebarPage>
      <Stack px={3} pt={3} pb={1} spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="ah2">Chats</Typography>
        </Stack>
        <FormControl>
          <InputLabel>Filter</InputLabel>
          <Select
            label="Filter"
            value={optionIndex}
            onChange={(event) => setOptionIndex(event.target.value as number)}
            startAdornment={
              <InputAdornment position="start">
                <FilterAltIcon />
              </InputAdornment>
            }
          >
            {OPTIONS.map((option, index) => (
              <MenuItem key={option.label} value={index}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      {error && <ErrorView error={error} />}
      <MainSecondarySidebarList>
        {chats
          .sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis())
          .map((chat) => (
            <ListItemButton
              key={chat.id}
              to={`/chats/${chat.id}`}
              component={NavLink}
            >
              {toLocaleDateTimeString(chat.createdAt)}
            </ListItemButton>
          ))}
        {controller.hasMore && (
          <Stack mx={2} my={1}>
            <Button onClick={controller.loadMore}>Load more</Button>
          </Stack>
        )}
      </MainSecondarySidebarList>
    </MainSecondarySidebarPage>
  );
};
