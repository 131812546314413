import {
    AttachmentType,
    Dimensions,
    ElevenLabsModel,
    Gender,
    TalentInterest,
    TalentPersonalityTrait,
    TalentPurpose,
} from "@zall-bot/types";
import { LlmType } from "@zall-bot/types/types/LlmType";
import { z } from "zod";

export const FormValues = z.object({
  name: z.string().nonempty(),
  tag: z.string().nonempty(),
  profilePictureFilePath: z.string(),
  gender: Gender,
  age: z.number().int().positive(),
  elevenLabsVoiceId: z.string(),
  elevenLabsStability: z.number().min(0).max(1),
  elevenLabsSimilarityBoost: z.number().min(0).max(1),
  elevenLabsStyle: z.number().min(0).max(1),
  elevenLabsModel: ElevenLabsModel,
  isAdult: z.boolean(),
  hasCustomChatPrompt: z.boolean(),
  chatPrompt: z.string(),
  facts: z.string(),
  creditsToSendMessage: z.number(),
  creditsToUnlockImage: z.number(),
  creditsToUnlockAudio: z.number(),
  revenueShare: z.number().min(0).max(1),
  purpose: TalentPurpose,
  personalityTraits: z.array(TalentPersonalityTrait),
  interests: z.array(TalentInterest),
  chatMessageForAuth: z.string(),
  chatMessageForCheckout: z.string(),
  chatExampleCount: z.number(),
  chatExamples: z.array(
    z.object({
      requestText: z.string().nonempty(),
      responseText: z.string().nonempty(),
    })
  ),
  chatMessageSuggestions: z.array(
    z.object({
      id: z.string(),
      text: z.string(),
    })
  ),
  initialChatMessages: z.array(
    z.object({
      id: z.string(),
      text: z.string().nonempty(),
      attachments: z
        .array(
          z
            .object({
              filePath: z.string().nonempty(),
              isUnlocked: z.boolean(),
            })
            .and(
              z.union([
                z.object({
                  type: z.literal("IMAGE" satisfies AttachmentType),
                  dimensions: Dimensions,
                }),
                z.object({
                  type: z.literal("AUDIO" satisfies AttachmentType),
                }),
              ])
            )
        )
        .optional(),
    })
  ),
  staticAttachments: z.array(
    z
      .object({
        id: z.string(),
        originalFileName: z.string(),
        filePath: z.string().nonempty(),
        context: z.string().nonempty(),
      })
      .and(
        z.union([
          z.object({
            type: z.literal("IMAGE" satisfies AttachmentType),
            lockedFilePath: z.string(),
            dimensions: Dimensions,
          }),
          z.object({
            type: z.literal("AUDIO" satisfies AttachmentType),
          }),
        ])
      )
  ),
  llmType: LlmType,
  llmTemperature: z.number().min(0),
  llmModelId: z.string(),
  llmFineTunedModelId: z.string(),
  referralRevenueShareForInvitees: z.number().min(0).max(1),
});

export type FormValues = z.infer<typeof FormValues>;
