import { isTalent, Talent } from "@zall-bot/types";
import { onSnapshot, query, where } from "firebase/firestore";
import { create } from "zustand";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { getDataFromCollectionSnapshot } from "../../Firebase/helpers/getDataFromQuerySnapshot";

interface State {
  talents: Talent[];
  isInitializing: boolean;
  subscribe: (talentIds: string[]) => void;
  unsubscribe?: () => void;
}

export const useMyTalents = create<State>((set) => ({
  talents: [],
  isInitializing: true,
  subscribe: (talentIds) => {
    const unsubscribeFirestore = onSnapshot(
      query(getCollectionRef("talents"), where("id", "in", talentIds)),
      (snapshot) => {
        if (snapshot.metadata.hasPendingWrites) return;

        const talents = getDataFromCollectionSnapshot(isTalent, snapshot);

        set({ talents, isInitializing: false });
      },
      () => {
        set({ talents: [], isInitializing: false });
      }
    );

    set({
      unsubscribe: () => {
        set({ talents: [], isInitializing: true });
        unsubscribeFirestore();
      },
    });
  },
}));
