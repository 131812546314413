import { Stack, TextField } from "@mui/material";
import { ChatPrompt } from "@zall-bot/types";
import { FC } from "react";

interface Props {
  chatPrompt: ChatPrompt;
}

export const ChatPromptDialogTabOutput: FC<Props> = (props) => {
  const { chatPrompt } = props;

  return (
    <Stack spacing={3}>
      {chatPrompt.tokenUsage && (
        <Stack direction="row" spacing={3}>
          <TextField
            value={chatPrompt.tokenUsage.promptTokens}
            label="Prompt Tokens"
            sx={{ textarea: { fontSize: 14 } }}
            fullWidth
          />
          <TextField
            value={chatPrompt.tokenUsage.completionTokens}
            label="Completion Tokens"
            sx={{ textarea: { fontSize: 14 } }}
            fullWidth
          />
          <TextField
            value={chatPrompt.tokenUsage.totalTokens}
            label="Total Tokens"
            sx={{ textarea: { fontSize: 14 } }}
            fullWidth
          />
        </Stack>
      )}

      <TextField
        value={chatPrompt.responseText}
        label="Response Text"
        sx={{ textarea: { fontSize: 14 } }}
        fullWidth
        multiline
      />

      {chatPrompt.responseFunction && (
        <TextField
          value={stringifyResponseFunction(chatPrompt.responseFunction)}
          label="Response Function"
          sx={{ textarea: { fontSize: 14 } }}
          fullWidth
          multiline
        />
      )}
    </Stack>
  );
};

function stringifyResponseFunction(
  responseFunction: ChatPrompt["responseFunction"]
) {
  if (!responseFunction?.arguments) return;
  let args: unknown;

  try {
    args = JSON.parse(responseFunction.arguments);
  } catch (error) {
    if (!(error instanceof Error)) return;
    args = { invalidJson: error.message };
  }

  return JSON.stringify(
    {
      name: responseFunction.name,
      arguments: args,
    },
    null,
    4
  );
}
