import { Chip } from "@mui/material";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { useTalent } from "../../hooks/useTalent";

interface Props {
  talentId: string;
}

export const TalentChip: FC<Props> = (props) => {
  const { talentId } = props;
  const [talent, isLoading] = useTalent(talentId);

  if (isLoading) return null;
  if (!talent) return null;

  return (
    <Chip
      to={`/talents/${talent.id}`}
      component={NavLink}
      label={talent.name}
    />
  );
};
