import { EarningFrame } from "@zall-bot/types";
import { DateTime } from "luxon";
import { FC, useMemo } from "react";
import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
} from "recharts";
import { toLocaleMoney } from "../../Common/helpers/toLocaleMoney";
import { MAIN_PALETTE } from "../../Main/consts/MAIN_PALETTE";
import { normalizeEarningFrames } from "../../Talent/helpers/normalizeEarningFrames";

interface Props {
  earningFrames: EarningFrame[];
}

export const CreatorEarningsLineChart: FC<Props> = (props) => {
  const { earningFrames } = props;

  const normalizedEarningFrames = useMemo(() => {
    const today = DateTime.now().startOf("day");

    return normalizeEarningFrames({
      earningFrames,
      toDate: today,
      fromDate: today.minus({ days: 30 }),
    });
  }, [earningFrames]);

  return (
    <ResponsiveContainer height={250}>
      <LineChart data={normalizedEarningFrames}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="id" visibility="hidden" />
        <Tooltip
          formatter={(value) =>
            toLocaleMoney({ amount: typeof value === "number" ? value : 0 })
          }
        />
        <Line
          type="monotone"
          dataKey="totalNetAmount"
          name="Total Net"
          stroke={MAIN_PALETTE.primary.main}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
