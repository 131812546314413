import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Chip,
  InputAdornment,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  AppConfig,
  Money,
  SYSTEM_MESSAGE_PLACEHOLDERS,
} from "@zall-bot/types";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { updateAppConfigCallable } from "../callables/updateAppConfigCallable";

const FormValues = z.object({
  chatPromptTemplate: z.string().nonempty(),
  talentChatPromptTemplate: z.string().nonempty(),
  creditRewardForSignUp: z.number(),
  creditRewardForAnonymousUser: z.number(),
  pricePerCredit: Money,
  referralRevenueShare: z.number().min(0).max(1),
  functionSchemaConfig: z.object({
    generateImageAttachment: z.object({
      description: z.string().nonempty(),
      contextDescription: z.string().nonempty(),
      successTextDescription: z.string().nonempty(),
      failureTextDescription: z.string().nonempty(),
    }),
  }),
});

type FormValues = z.infer<typeof FormValues>;

interface Props {
  appConfig: AppConfig;
}

export const AppConfigForm: FC<Props> = (props) => {
  const { appConfig } = props;

  const { control, handleSubmit, formState } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      chatPromptTemplate: appConfig.chatPromptTemplate,
      talentChatPromptTemplate: appConfig.talentChatPromptTemplate,
      creditRewardForSignUp: appConfig.creditRewardForSignUp,
      creditRewardForAnonymousUser: appConfig.creditRewardForAnonymousUser,
      pricePerCredit: appConfig.pricePerCredit,
      referralRevenueShare: appConfig.referralRevenueShare,
      functionSchemaConfig: {
        generateImageAttachment: {
          ...appConfig.functionSchemaConfig.generateImageAttachment,
        },
      },
    },
  });

  const onSubmit = useCallback(async (formValues: FormValues) => {
    try {
      await updateAppConfigCallable({
        chatPromptTemplate: formValues.chatPromptTemplate,
        talentChatPromptTemplate: formValues.talentChatPromptTemplate,
        creditRewardForAnonymousUser: formValues.creditRewardForAnonymousUser,
        creditRewardForSignUp: formValues.creditRewardForSignUp,
        pricePerCredit: formValues.pricePerCredit,
        referralRevenueShare: formValues.referralRevenueShare,
        functionSchemaConfig: formValues.functionSchemaConfig,
      });
      toast.success("Saved!");
    } catch (error) {
      handleError(error);
    }
  }, []);

  const isLoading = formState.isSubmitting;

  return (
    <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="h6">Template</Typography>
        <ControlledTextField
          control={control}
          label="Chat Prompt Template"
          name="chatPromptTemplate"
          autoComplete="off"
          multiline
        />
        <ControlledTextField
          control={control}
          label="Talent Chat Prompt Template"
          name="talentChatPromptTemplate"
          autoComplete="off"
          multiline
        />
        <Stack direction="row" spacing={1}>
          {SYSTEM_MESSAGE_PLACEHOLDERS.map((placeholder) => (
            <Chip key={placeholder} label={`{${placeholder}}`} />
          ))}
        </Stack>
      </Stack>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="h6">Credits</Typography>
        <ControlledTextField
          control={control}
          label="Free Credits For Sign Up"
          name="creditRewardForSignUp"
          type="number"
          autoComplete="off"
        />
        <ControlledTextField
          control={control}
          label="Free Credits For Anonymous User"
          name="creditRewardForAnonymousUser"
          type="number"
          autoComplete="off"
        />
        <ControlledTextField
          control={control}
          label="Price Per Credit"
          name="pricePerCredit.amount"
          type="number"
          startAdornment={
            <InputAdornment position="start">
              {appConfig.pricePerCredit.currency}
            </InputAdornment>
          }
          autoComplete="off"
        />
      </Stack>

      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="h6">Referrals</Typography>
        <ControlledTextField
          control={control}
          label="Revenue Share for Referrals"
          name="referralRevenueShare"
          inputProps={{ min: 0, max: 1, step: 0.001 }}
          type="number"
          autoComplete="off"
        />
      </Stack>

      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="h6">Function Schemas</Typography>
        <Typography variant="body1">Generate Image Attachments</Typography>
        <ControlledTextField
          control={control}
          label="Description"
          name="functionSchemaConfig.generateImageAttachment.description"
          autoComplete="off"
        />
        <ControlledTextField
          control={control}
          label="Context Description"
          name="functionSchemaConfig.generateImageAttachment.contextDescription"
          autoComplete="off"
        />
        <ControlledTextField
          control={control}
          label="Success Text Description"
          name="functionSchemaConfig.generateImageAttachment.successTextDescription"
          autoComplete="off"
        />
        <ControlledTextField
          control={control}
          label="Failure Text Description"
          name="functionSchemaConfig.generateImageAttachment.failureTextDescription"
          autoComplete="off"
        />
      </Stack>
      <Button
        size="large"
        type="submit"
        variant="contained"
        sx={{ alignSelf: "flex-start" }}
        disabled={isLoading}
      >
        Save
      </Button>
    </Stack>
  );
};
