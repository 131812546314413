import { isTalentConfig, TalentConfig } from "@zall-bot/types";
import { onSnapshot, query, where } from "firebase/firestore";
import { create } from "zustand";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { getDataFromCollectionSnapshot } from "../../Firebase/helpers/getDataFromQuerySnapshot";

interface State {
  talentConfigs: TalentConfig[];
  isInitializing: boolean;
  subscribe: (talentIds: string[]) => void;
  unsubscribe?: () => void;
}

export const useMyTalentConfigs = create<State>((set) => ({
  talentConfigs: [],
  isInitializing: true,
  subscribe: (talentIds) => {
    const unsubscribeFirestore = onSnapshot(
      query(getCollectionRef("talentConfigs"), where("id", "in", talentIds)),
      (snapshot) => {
        if (snapshot.metadata.hasPendingWrites) return;

        const talentConfigs = getDataFromCollectionSnapshot(
          isTalentConfig,
          snapshot
        );

        set({ talentConfigs, isInitializing: false });
      },
      () => {
        set({ talentConfigs: [], isInitializing: false });
      }
    );

    set({
      unsubscribe: () => {
        set({ talentConfigs: [], isInitializing: true });
        unsubscribeFirestore();
      },
    });
  },
}));
