import { TalentPersonalityTrait } from "@zall-bot/types";

interface RecordValue {
  label: string;
}

export const TALENT_PERSONALITY_TRAIT_RECORD: Record<
  TalentPersonalityTrait,
  RecordValue
> = {
  SWEET: {
    label: "😊 Sweet",
  },
  MYSTERIOUS: {
    label: "🔮 Mysterious",
  },
  CARING: {
    label: "🤗‍ Caring",
  },
  NAUGHTY: {
    label: "😏 Naughty",
  },
  CASUAL: {
    label: "😎 Casual",
  },
  FUNNY: {
    label: "🎉 Funny",
  },
  GENZ: {
    label: "📱 GenZ",
  },
  ATTITUDE: {
    label: "💁‍♂️ Attitude",
  },
  OLD_SOUL: {
    label: "👴 Old Soul",
  },
};
