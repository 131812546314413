import GoogleIcon from "@mui/icons-material/Google";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { signInWithRedirect } from "firebase/auth";
import { FC, useCallback } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { logEvent } from "../../Common/helpers/logEvent";
import { MainPage } from "../../Main/views/MainPage";
import { auth } from "../consts/FIREBASE";
import { SignInMethod, getAuthProvider } from "../helpers/getAuthProvider";
import { FirebaseAuthEmailForm } from "./FirebaseAuthEmailForm";

export const FirebaseAuthSignInPage: FC = () => {
  const signIn = useCallback(async (signInMethod: SignInMethod) => {
    try {
      await signInWithRedirect(auth, getAuthProvider(signInMethod));
      logEvent("signed_in");
    } catch (error) {
      handleError(error);
    }
  }, []);

  const submit = useCallback(
    async (signInMethod: SignInMethod) => {
      await signIn(signInMethod);
    },
    [signIn]
  );

  return (
    <MainPage>
      <Stack flex={1} p={2} justifyContent="center" spacing={4}>
        <Stack spacing={2}>
          <Typography variant="ah2"> Sign in</Typography>
        </Stack>

        <Stack spacing={1.5}>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            startIcon={<GoogleIcon sx={{ width: 28, height: 28 }} />}
            onClick={() => submit("google.com")}
          >
            Continue with Google
          </Button>
        </Stack>

        <Divider sx={{ fontWeight: 700 }}>or</Divider>

        <FirebaseAuthEmailForm />
      </Stack>
    </MainPage>
  );
};
