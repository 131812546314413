import { Stack, styled, Typography } from "@mui/material";
import { ChatMessage } from "@zall-bot/types";
import { FC, Fragment, useCallback } from "react";
import { StorageFile } from "../../Common/types/StorageFile";
import { toReactionEmoji } from "../helpers/toReactionEmoji";
import { useChatPromptDialog } from "../hooks/useChatPromptDialog";

interface Props {
  chatId?: string;
  chatMessage: ChatMessage;
  talentProfilePicture?: StorageFile;
}

export const ChatMessageView: FC<Props> = (props) => {
  const { chatId, chatMessage } = props;

  const isChatMessageTextVisible = !chatMessage.attachments
    ?.map((a) => a.type)
    .includes("AUDIO");

  const onClick = useCallback(() => {
    if (!chatId) return;

    useChatPromptDialog.getState().open(chatId, chatMessage.id);
  }, [chatId, chatMessage.id]);

  return (
    <Stack
      px={1}
      direction={chatMessage.senderType === "USER" ? "row-reverse" : "row"}
      justifyContent="flex-start"
      spacing={1}
    >
      <Stack
        maxWidth="80%"
        width={chatMessage.attachments?.length ? "100%" : undefined}
        onClick={onClick}
        sx={{ cursor: "pointer" }}
      >
        <Stack
          p={1.5}
          borderRadius="18px"
          border="1px solid"
          borderColor="grey.400"
          position="relative"
          spacing={1}
        >
          {chatMessage.attachments?.map((attachment, index) => (
            <Fragment key={index}>
              {attachment.type === "IMAGE" && <Stack>🏞️ Image</Stack>}
              {attachment.type === "AUDIO" && <Stack>🔈 Audio</Stack>}
            </Fragment>
          ))}
          {isChatMessageTextVisible && (
            <Typography variant="body2">{chatMessage.text}</Typography>
          )}
          {!!chatMessage.reaction && (
            <SReactionView>
              {toReactionEmoji(chatMessage.reaction)}
            </SReactionView>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

const REACTION_SIZE = 24;

const SReactionView = styled(Stack)(({ theme }) => ({
  position: "absolute",
  bottom: REACTION_SIZE / -3,
  right: theme.spacing(1),
  width: REACTION_SIZE,
  height: REACTION_SIZE,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.palette.grey[400],
  backgroundColor: theme.palette.grey[500],
  fontSize: 12,
  borderRadius: REACTION_SIZE / 2,
  alignItems: "center",
  justifyContent: "center",
}));
