import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SxProps,
  Theme,
} from "@mui/material";
import { SelectInputProps } from "@mui/material/Select/SelectInput";
import { ReactNode } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

export type Item<TValue> = {
  value: TValue;
  label: string;
  disabled?: boolean;
};

type Value = string | number;

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TValue extends Value
> {
  control: Control<TFieldValues>;
  name: TName;
  items: Item<TValue>[];
  renderItem: (item: Item<TValue>) => ReactNode;
  label: ReactNode;
  sx?: SxProps<Theme>;
  required?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  helperText?: string;
  disabled?: boolean;
  multiple?: boolean;
  size?: FormControlProps["size"];
  onChange?: () => void;
  emptyLabel?: string;
  onPressEnter?: () => void;
}

export function ControlledSelect<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TValue extends Value
>(props: Props<TFieldValues, TName, TValue>) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        ...(props.required && { required: "Required" }),
      }}
      render={({ field, fieldState }) => (
        <FormControl
          error={!!fieldState.error}
          fullWidth={props.fullWidth}
          disabled={props.disabled}
        >
          <InputLabel
            id={`${props.name}-select-label`}
            shrink={props.emptyLabel ? true : undefined}
          >
            {props.label}
          </InputLabel>
          <Select
            {...field}
            label={props.label}
            labelId={`${props.name}-select-label`}
            error={!!fieldState.error}
            placeholder={props.placeholder}
            required={props.required}
            fullWidth={props.fullWidth}
            size={props.size}
            sx={props.sx}
            displayEmpty={!!props.emptyLabel}
            input={
              props.emptyLabel ? (
                <OutlinedInput notched label={props.label} />
              ) : undefined
            }
            multiple={props.multiple}
            {...(props.onPressEnter && {
              onKeyDown: (e) => e.key === "Enter" && props.onPressEnter?.(),
            })}
            onChange={(event) => {
              field.onChange(event);
              props.onChange?.();
            }}
          >
            {!!props.emptyLabel && (
              <MenuItem value="">
                <em>{props.emptyLabel}</em>
              </MenuItem>
            )}
            {props.items.map((item) => {
              return (
                <MenuItem
                  key={item.value}
                  value={item.value}
                  disabled={item.disabled}
                >
                  {props.renderItem(item)}
                </MenuItem>
              );
            })}
          </Select>
          {(props.helperText || fieldState.error?.message) && (
            <FormHelperText>
              {props.helperText || fieldState.error?.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
