import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  Switch,
  SwitchProps,
  SxProps,
  Theme,
} from "@mui/material";
import { ChangeEvent, ReactNode } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> {
  control: Control<TFieldValues & Record<TName, boolean>>;
  name: TName;
  label?: ReactNode;
  sx?: SxProps<Theme>;
  required?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  disabled?: boolean;
  size?: SwitchProps["size"];
  color?: SwitchProps["color"];
  labelPlacement?: FormControlLabelProps["labelPlacement"];
}

export function ControlledSwitch<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(props: Props<TFieldValues, TName>) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        ...(props.required && { required: "Required" }),
      }}
      render={({ field: { value, onChange, ...restField }, fieldState }) => (
        <FormControl error={!!fieldState.error} fullWidth={props.fullWidth}>
          <FormControlLabel
            disabled={props.disabled}
            label={props.label}
            sx={{ ...(!props.label && { mr: 0 }) }}
            labelPlacement={props.labelPlacement}
            checked={value}
            onChange={(e) =>
              onChange((e as ChangeEvent<HTMLInputElement>).target.checked)
            }
            control={<Switch color={props.color} size={props.size} />}
            {...restField}
          />
          {(props.helperText || fieldState.error?.message) && (
            <FormHelperText>
              {props.helperText || fieldState.error?.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
