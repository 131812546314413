import { FC, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../Firebase/consts/FIREBASE";
import { useMyTalentConfigs } from "../../Talent/hooks/useMyTalentConfigs";
import { useMyTalents } from "../../Talent/hooks/useMyTalents";
import { useMyUser } from "../../User/hooks/useMyUser";
import { useMainInit } from "../hooks/useMainInit";

export const MainInitView: FC = () => {
  const [authState, isInitializingAuthState] = useAuthState(auth);
  const isInitializingMyUser = useMyUser((state) => state.isInitializing);
  const managedTalentIdsString = useMyUser((state) =>
    state.user?.managedTalentIds?.join(",")
  );
  const isInitializingMyTalents = useMyTalents((state) => state.isInitializing);
  const isInitializingMyTalentConfigs = useMyTalentConfigs(
    (state) => state.isInitializing
  );
  const userId = authState?.uid;

  // Dependency: userId
  useEffect(() => {
    if (userId) {
      useMyUser.getState().subscribe(userId);
    }

    return () => {
      useMyUser.getState().unsubscribe?.();
    };
  }, [userId]);

  // Dependency: managedTalentIds
  useEffect(() => {
    if (managedTalentIdsString) {
      const managedTalentIds = managedTalentIdsString.split(",");

      useMyTalents.getState().subscribe(managedTalentIds);
      useMyTalentConfigs.getState().subscribe(managedTalentIds);
    }

    return () => {
      useMyTalents.getState().unsubscribe?.();
      useMyTalentConfigs.getState().unsubscribe?.();
    };
  }, [managedTalentIdsString]);

  const isInitializing =
    isInitializingAuthState ||
    (userId ? isInitializingMyUser : false) ||
    (managedTalentIdsString ? isInitializingMyTalents : false) ||
    (managedTalentIdsString ? isInitializingMyTalentConfigs : false);

  useEffect(() => {
    useMainInit.setState({ isInitializing });
  }, [isInitializing]);

  return null;
};
