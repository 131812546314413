import { z } from "zod";

export const LLAMA_TYPES = ["llama2-7b", "llama2-13b", "llama2-70b"] as const;
export const LlamaType = z.enum(LLAMA_TYPES);
export type LlamaType = z.infer<typeof LlamaType>;

export const GPT_TYPES = ["gpt-3.5", "gpt-3.5-fine-tuned"] as const;
export const GptType = z.enum(GPT_TYPES);
export type GptType = z.infer<typeof GptType>;
export const LLM_TYPES = [...LLAMA_TYPES, ...GPT_TYPES] as const;
export const LlmType = z.union([LlamaType, GptType]);
export type LlmType = z.infer<typeof LlmType>;
