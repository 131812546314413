import { Chat, FirebaseFilter, isChat } from "@zall-bot/types";
import { orderBy, query, where } from "firebase/firestore";
import { usePaginatedCollection } from "../../Common/hooks/usePaginatedCollection";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";

export function useChats(filters: FirebaseFilter<Chat>[]) {
  return usePaginatedCollection(
    query(
      getCollectionRef("chats"),
      ...filters.map((filter) => where(...filter)),
      orderBy("createdAt", "desc")
    ),
    isChat
  );
}
