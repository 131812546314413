import { TalentInterest } from "@zall-bot/types";

interface RecordValue {
  label: string;
}

export const TALENT_INTEREST_RECORD: Record<TalentInterest, RecordValue> = {
  ANIME: {
    label: "📺 Anime",
  },
  ART: {
    label: "🎨 Art",
  },
  BAKING: {
    label: "🍰 Baking",
  },
  BEACH: {
    label: "🏖️ Beach",
  },
  BIRDWATCHING: {
    label: "🐦 Birdwatching",
  },
  CAMPING: {
    label: "⛺ Camping",
  },
  CATS: {
    label: "🐱 Cats",
  },
  COOKING: {
    label: "👩‍🍳 Cooking",
  },
  CRAFTING: {
    label: "🧶 Crafting",
  },
  CYCLING: {
    label: "🚴‍♀️ Cycling",
  },
  DANCING: {
    label: "💃 Dancing",
  },
  DATING: {
    label: "😏 Dating",
  },
  DESIGN: {
    label: "✒️ Design",
  },
  DOGS: {
    label: "🐶 Dogs",
  },
  EDM: {
    label: "🎚 EDM",
  },
  FAMILY: {
    label: "👪 Family",
  },
  FASHION: {
    label: "👗 Fashion",
  },
  FISHING: {
    label: "🎣 Fishing",
  },
  GARDENING: {
    label: "🌱 Gardening",
  },
  GYM: {
    label: "🏋️‍♀️ Gym",
  },
  HIKING: {
    label: "🥾 Hiking",
  },
  HIP_HOP: {
    label: "🎤 Hip Hop",
  },
  MEDITATION: {
    label: "🧘‍♀️ Meditation",
  },
  PAINTING: {
    label: "🎨 Painting",
  },
  PARTYING: {
    label: "🥳 Partying",
  },
  PHILOSOPHY: {
    label: "🤔 Philosophy",
  },
  PHOTOGRAPHY: {
    label: "📸 Photography",
  },
  READING: {
    label: "📚 Reading",
  },
  ROMCOMS: {
    label: "📽️ Romcoms",
  },
  RUNNING: {
    label: "🏃‍♀️ Running",
  },
  SINGING: {
    label: "🎶 Singing",
  },
  SKATEBOARDING: {
    label: "🛹 Skateboarding",
  },
  STARGAZING: {
    label: "🌌 Stargazing",
  },
  SURFING: {
    label: "🏄‍♀️ Surfing",
  },
  SWIMMING: {
    label: "🏊‍♀️ Swimming",
  },
  TRAVELING: {
    label: "🌍 Traveling",
  },
  VIDEO_GAMES: {
    label: "🎮 Video Games",
  },
  WINTERSPORTS: {
    label: "🏂 Wintersports",
  },
  WOODWORKING: {
    label: "🪵 Woodworking",
  },
  WRITING: {
    label: "📝 Writing",
  },
  YOGA: {
    label: "🧘‍♀️ Yoga",
  },
};
