import { isEarningFrame } from "@zall-bot/types";
import { query } from "firebase/firestore";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { useValidCollectionData } from "../../Firebase/hooks/useValidCollectionData";

export function useEarningFrames(talentId: string) {
  return useValidCollectionData(
    talentId
      ? query(getCollectionRef(`talentConfigs/${talentId}/earningFrames`))
      : null,
    isEarningFrame
  );
}
