import { zodResolver } from "@hookform/resolvers/zod";
import SaveIcon from "@mui/icons-material/Save";
import { Button, Divider, Paper, Stack, Typography } from "@mui/material";
import { MODEL_MESSAGE_ROLES, Model, Talent } from "@zall-bot/types";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { updateModelCallable } from "../callables/updateModelCallable";
import { MODEL_MESSAGE_ROLE_RECORD } from "../consts/MODEL_MESSAGE_ROLE_RECORD";
import { MODEL_NEW } from "../consts/MODEL_NEW";
import { toModelTrainingExamples } from "../helpers/toModelTrainingExamples";
import { toModelTrainingExamplesString } from "../helpers/toModelTrainingExamplesString";
import { ModelVersionsView } from "./ModelVersionsView";

const FormValues = z.object({
  name: z.string().nonempty(),
  trainingExamples: z.string().superRefine((value, ctx) => {
    try {
      toModelTrainingExamples(value);
    } catch (error) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: error instanceof Error ? error.message : "Invalid",
      });
    }
  }),
});

type FormValues = z.infer<typeof FormValues>;

interface Props {
  model: Model;
  talent?: Talent;
}

export const ModelForm: FC<Props> = (props) => {
  const { model, talent } = props;

  const { control, handleSubmit, formState } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      name: talent?.name || model.name,
      trainingExamples: toModelTrainingExamplesString(model.trainingExamples),
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        await updateModelCallable({
          id: model.id,
          name: formValues.name,
          trainingExamples: toModelTrainingExamples(
            formValues.trainingExamples
          ),
        });

        toast.success("Saved!");
      } catch (error) {
        handleError(error);
      }
    },
    [model]
  );

  const isLoading = formState.isSubmitting;

  return (
    <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack component={Paper} p={2} spacing={3}>
        <ControlledTextField
          control={control}
          label="Name"
          name="name"
          autoComplete="off"
          disabled={!!talent || isLoading}
        />
      </Stack>
      <Typography variant="ah3">Training Data</Typography>
      <Stack component={Paper} p={2} spacing={3}>
        <ControlledTextField
          control={control}
          label="Examples"
          name="trainingExamples"
          autoComplete="off"
          minRows={5}
          maxRows={20}
          multiline
          disabled={isLoading}
        />
        <Typography variant="caption">
          Every line has to start with{" "}
          {MODEL_MESSAGE_ROLES.map(
            (role) => `"${MODEL_MESSAGE_ROLE_RECORD[role].short}: "`
          ).join(" or ")}
          .<br />
          Start a new example with {`"${MODEL_NEW}"`}.
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Button
          size="large"
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          disabled={isLoading}
        >
          Save
        </Button>
      </Stack>
      <Divider />
      <ModelVersionsView model={model} />
    </Stack>
  );
};
