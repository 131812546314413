import { Money } from "@zall-bot/types";

/**
 * TODO: Delete this file and get data from server!
 */
export const DEFAULT_APP_CONFIG = {
  revenueShare: 0.6,
  referralRevenueShare: 0.03,
  pricePerCredit: {
    amount: 0.1,
    currency: "USD",
  } as Money,
};
