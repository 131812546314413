import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ErrorIcon from "@mui/icons-material/Error";
import ScienceIcon from "@mui/icons-material/Science";
import { ChipOwnProps, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { ModelVersionStatus } from "@zall-bot/types";

interface RecordValue {
  Icon: OverridableComponent<SvgIconTypeMap>;
  label: string;
  color: ChipOwnProps["color"];
  isLoading: boolean;
}

export const MODEL_VERSION_STATUS_RECORD: Record<
  ModelVersionStatus,
  RecordValue
> = {
  UPLOADING_TRAINING_FILE: {
    Icon: CloudUploadIcon,
    label: "Uploading",
    color: "default",
    isLoading: true,
  },
  FINE_TUNING: {
    Icon: ScienceIcon,
    label: "Fine Tuning",
    color: "default",
    isLoading: true,
  },
  SUCCEEDED: {
    Icon: CheckCircleIcon,
    label: "Succeeded",
    color: "success",
    isLoading: false,
  },
  FAILED: {
    Icon: ErrorIcon,
    label: "Failed",
    color: "error",
    isLoading: false,
  },
};
