import { zodResolver } from "@hookform/resolvers/zod";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Button,
    CircularProgress,
    IconButton,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import {
    AppConfig,
    CURRENT_TALENT_INTERESTS,
    CurrentTalentInterest,
    ELEVEN_LABS_MODELS,
    GENDERS,
    Model,
    TALENT_INTERESTS,
    TALENT_PERSONALITY_TRAITS,
    TALENT_PURPOSES,
    Talent,
    TalentConfig,
} from "@zall-bot/types";
import { LLM_TYPES } from "@zall-bot/types/types/LlmType";
import { FC, Fragment, useCallback } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { v4 } from "uuid";
import { handleError } from "../../../Common/helpers/handleError";
import { toLocaleDateTimeString } from "../../../Common/helpers/toLocaleDateTimeString";
import { useUploadFiles } from "../../../Common/hooks/useUploadFiles";
import { FileInput } from "../../../Common/views/FileInput";
import { ControlledSelect } from "../../../Form/views/ControlledSelect";
import { ControlledSwitch } from "../../../Form/views/ControlledSwitch";
import { ControlledTextField } from "../../../Form/views/ControlledTextField";
import { useModelVersions } from "../../../Model/hooks/useModelVersions";
import { adminUpdateTalentCallable } from "../../callables/adminUpdateTalentCallable";
import { TALENT_INTEREST_RECORD } from "../../consts/TALENT_INTEREST_RECORD";
import { TALENT_PERSONALITY_TRAIT_RECORD } from "../../consts/TALENT_PERSONALITY_TRAIT_RECORD";
import { TALENT_PURPOSE_RECORD } from "../../consts/TALENT_PURPOSE_RECORD";
import { useTalentConfigs } from "../../hooks/useTalentConfigs";
import { AttachmentFormFields } from "./AttachmentFormFields";
import { FilePreview } from "./FilePreview";
import { FormValues } from "./FormValues";
import { TalentChip } from "./TalentChip";

interface Props {
  talent: Talent;
  talentConfig: TalentConfig;
  models: Model[];
  appConfig: AppConfig;
}

export const TalentForm: FC<Props> = (props) => {
  const { talent, talentConfig, models, appConfig } = props;
  const [referralTalentConfigs] = useTalentConfigs([
    ["referralTalentId", "==", talent.id],
  ]);

  const form = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      name: talent.name,
      tag: talent.tag,
      profilePictureFilePath: talent.profilePictureFilePath,
      gender: talent.gender,
      age: talent.age,
      elevenLabsVoiceId: talentConfig.elevenLabsVoiceId,
      elevenLabsStability: talentConfig.elevenLabsStability,
      elevenLabsSimilarityBoost: talentConfig.elevenLabsSimilarityBoost,
      elevenLabsStyle: talentConfig.elevenLabsStyle,
      elevenLabsModel: talentConfig.elevenLabsModel,
      creditsToSendMessage: talent.creditsToSendMessage,
      creditsToUnlockImage: talent.creditsToUnlockImage,
      creditsToUnlockAudio: talent.creditsToUnlockAudio,
      revenueShare: talentConfig.revenueShare,
      purpose: talentConfig.purpose || "FLIRT",
      interests: talentConfig.interests || [],
      personalityTraits: talentConfig.personalityTraits || [],
      isAdult: talent.isAdult,
      hasCustomChatPrompt: !!talentConfig.hasCustomChatPrompt,
      chatPrompt: talentConfig.chatPrompt,
      facts: talentConfig.facts,
      chatExampleCount: talentConfig.chatExampleCount,
      chatExamples: talentConfig.chatExamples,
      initialChatMessages: talent.initialChatMessages,
      staticAttachments: talentConfig.staticAttachments,
      llmType: talentConfig.llmType,
      llmTemperature: talentConfig.llmTemperature,
      llmModelId: talentConfig.llmModelId || "",
      llmFineTunedModelId: talentConfig.llmFineTunedModelId || "",
      chatMessageForAuth: talent.chatMessageForAuth || "",
      chatMessageForCheckout: talent.chatMessageForCheckout || "",
      chatMessageSuggestions: talent.chatMessageSuggestions || [],
      referralRevenueShareForInvitees:
        talentConfig.referralRevenueShareForInvitees ||
        appConfig.referralRevenueShare,
    },
  });

  const { control, handleSubmit, formState, watch, setValue } = form;

  const llmType = watch("llmType");
  const llmModelId = watch("llmModelId");
  const hasCustomChatPrompt = watch("hasCustomChatPrompt");

  const [versions, isLoadingVersions] = useModelVersions(llmModelId);

  const chatExamples = useFieldArray({
    control,
    name: "chatExamples",
  });

  const initialChatMessages = useFieldArray({
    control,
    name: "initialChatMessages",
  });

  const chatMessageSuggestions = useFieldArray({
    control,
    name: "chatMessageSuggestions",
  });

  const staticAttachments = useFieldArray({
    control,
    name: "staticAttachments",
  });

  const [uploadFiles, isUploadingFiles] = useUploadFiles({
    ownerId: talent.id,
    ownerType: "TALENT",
    onFileUploaded: (uploadedFile, originalFile) => {
      switch (uploadedFile.type) {
        case "IMAGE":
          staticAttachments.append({
            id: uploadedFile.id,
            type: "IMAGE",
            originalFileName: originalFile.name,
            filePath: uploadedFile.filePath,
            lockedFilePath: uploadedFile.lockedFilePath,
            dimensions: uploadedFile.dimensions,
            context: "",
          });
          break;
        case "AUDIO":
          staticAttachments.append({
            id: uploadedFile.id,
            type: "AUDIO",
            originalFileName: originalFile.name,
            filePath: uploadedFile.filePath,
            context: "",
          });
          break;
      }
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        await adminUpdateTalentCallable({
          talentId: talent.id,
          name: formValues.name,
          tag: formValues.tag,
          profilePictureFilePath: formValues.profilePictureFilePath,
          gender: formValues.gender,
          age: formValues.age,
          elevenLabsVoiceId: formValues.elevenLabsVoiceId,
          elevenLabsStability: formValues.elevenLabsStability,
          elevenLabsSimilarityBoost: formValues.elevenLabsSimilarityBoost,
          elevenLabsStyle: formValues.elevenLabsStyle,
          elevenLabsModel: formValues.elevenLabsModel,
          creditsToSendMessage: formValues.creditsToSendMessage,
          creditsToUnlockImage: formValues.creditsToUnlockImage,
          creditsToUnlockAudio: formValues.creditsToUnlockAudio,
          revenueShare: formValues.revenueShare,
          isAdult: formValues.isAdult,
          hasCustomChatPrompt: formValues.hasCustomChatPrompt,
          chatPrompt: formValues.chatPrompt,
          facts: formValues.facts,
          purpose: formValues.purpose,
          interests: formValues.interests,
          personalityTraits: formValues.personalityTraits,
          chatExampleCount: formValues.chatExampleCount,
          chatExamples: formValues.chatExamples,
          initialChatMessages: formValues.initialChatMessages,
          staticAttachments: formValues.staticAttachments,
          llmType: formValues.llmType,
          llmTemperature: formValues.llmTemperature,
          llmModelId: formValues.llmModelId,
          llmFineTunedModelId: formValues.llmFineTunedModelId,
          chatMessageForAuth: formValues.chatMessageForAuth,
          chatMessageForCheckout: formValues.chatMessageForCheckout,
          chatMessageSuggestions: formValues.chatMessageSuggestions,
          referralRevenueShareForInvitees:
            formValues.referralRevenueShareForInvitees,
        });
        toast.success("Saved!");
      } catch (error) {
        handleError(error);
      }
    },
    [talent.id]
  );

  const isLoading = formState.isSubmitting;

  return (
    <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">Settings</Typography>
        <ControlledTextField
          control={control}
          label="Name"
          name="name"
          autoComplete="off"
          disabled={isLoading}
        />
        <ControlledTextField
          control={control}
          label="Handle"
          name="tag"
          autoComplete="off"
          disabled={isLoading}
        />
        <ControlledTextField
          control={control}
          label="Profile Picture File Path"
          name="profilePictureFilePath"
          autoComplete="off"
          disabled={isLoading}
        />
        <Stack direction="row" spacing={3}>
          <ControlledSelect
            control={control}
            label="Gender"
            name="gender"
            items={GENDERS.map((gender) => ({
              label: gender,
              value: gender,
            }))}
            renderItem={(item) => item.value}
            disabled={isLoading}
            fullWidth
          />
          <ControlledTextField
            control={control}
            label="Age"
            name="age"
            type="number"
            autoComplete="off"
            disabled={isLoading}
            fullWidth
          />
        </Stack>
        <Stack direction="row" spacing={3}>
          <ControlledTextField
            control={control}
            label="Credits to Send Message"
            name="creditsToSendMessage"
            type="number"
            autoComplete="off"
            disabled={isLoading}
            fullWidth
          />
          <ControlledTextField
            control={control}
            label="Credits to Unlock Image"
            name="creditsToUnlockImage"
            type="number"
            autoComplete="off"
            disabled={isLoading}
            fullWidth
          />
          <ControlledTextField
            control={control}
            label="Credits to Unlock Audio"
            name="creditsToUnlockAudio"
            type="number"
            autoComplete="off"
            disabled={isLoading}
            fullWidth
          />
          <ControlledTextField
            control={control}
            label="Revenue Share"
            name="revenueShare"
            type="number"
            inputProps={{ step: 0.1, min: 0, max: 1 }}
            autoComplete="off"
            disabled={isLoading}
            fullWidth
          />
        </Stack>
        <ControlledTextField
          control={control}
          label="Chat Message for Sign Up"
          name="chatMessageForAuth"
          autoComplete="off"
          disabled={isLoading}
        />
        <ControlledTextField
          control={control}
          label="Chat Message for Checkout"
          name="chatMessageForCheckout"
          autoComplete="off"
          disabled={isLoading}
        />
        <ControlledSwitch
          control={control}
          label="Is Adult"
          name="isAdult"
          disabled={isLoading}
        />
      </Stack>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">Referral</Typography>
        <Stack direction="row" spacing={3}>
          <Stack flex={1} spacing={3}>
            <Typography fontWeight={700}>Inviter</Typography>
            <TextField
              label="Revenue Share"
              value={
                talentConfig.referralTalentId
                  ? talentConfig.referralRevenueShareForInviter ||
                    `${appConfig.referralRevenueShare} (default)`
                  : "No Referral"
              }
              fullWidth
              disabled
            />
            <Stack spacing={0.5} alignItems="flex-start">
              {talentConfig.referralTalentId && (
                <TalentChip talentId={talentConfig.referralTalentId} />
              )}
            </Stack>
          </Stack>
          <Stack flex={1} spacing={3}>
            <Typography fontWeight={700}>Invitees</Typography>
            <ControlledTextField
              control={control}
              label="Revenue Share"
              name="referralRevenueShareForInvitees"
              type="number"
              inputProps={{ step: 0.001, min: 0, max: 1 }}
              autoComplete="off"
              disabled={isLoading}
              fullWidth
            />
            <Stack spacing={0.5} alignItems="flex-start">
              {referralTalentConfigs.map((talentConfig) => (
                <TalentChip key={talentConfig.id} talentId={talentConfig.id} />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">Eleven Labs</Typography>
        <Stack direction="row" spacing={3}>
          <ControlledTextField
            control={control}
            label="Voice ID"
            name="elevenLabsVoiceId"
            autoComplete="off"
            disabled={isLoading}
            fullWidth
          />
          <ControlledSelect
            control={control}
            label="Model"
            name="elevenLabsModel"
            items={ELEVEN_LABS_MODELS.map((model) => ({
              label: model,
              value: model,
            }))}
            renderItem={(item) => item.value}
            disabled={isLoading}
            fullWidth
          />
        </Stack>
        <Stack direction="row" spacing={3}>
          <ControlledTextField
            control={control}
            inputProps={{ step: 0.1, min: 0, max: 1 }}
            type="number"
            label="Stability"
            name="elevenLabsStability"
            autoComplete="off"
            disabled={isLoading}
            fullWidth
          />
          <ControlledTextField
            control={control}
            inputProps={{ step: 0.1, min: 0, max: 1 }}
            type="number"
            label="Similarity Boost"
            name="elevenLabsSimilarityBoost"
            autoComplete="off"
            disabled={isLoading}
            fullWidth
          />
          <ControlledTextField
            control={control}
            inputProps={{ step: 0.1, min: 0, max: 1 }}
            type="number"
            label="Style"
            name="elevenLabsStyle"
            autoComplete="off"
            disabled={isLoading}
            fullWidth
          />
        </Stack>
      </Stack>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">Prompts</Typography>
        <Stack direction="row" spacing={3}>
          <ControlledSelect
            control={control}
            label="Model"
            name="llmType"
            items={LLM_TYPES.map((model) => ({
              label: model,
              value: model,
            }))}
            renderItem={(item) => item.value}
            disabled={isLoading}
            fullWidth
          />
          <ControlledTextField
            control={control}
            label="Temperature"
            name="llmTemperature"
            autoComplete="off"
            inputProps={{ step: 0.01 }}
            type="number"
            disabled={isLoading}
            fullWidth
          />
        </Stack>
        {llmType === "gpt-3.5-fine-tuned" && (
          <Stack direction="row" spacing={3}>
            <Stack flex={1}>
              <ControlledSelect
                control={control}
                label="Custom Model"
                name="llmModelId"
                items={models
                  .filter((m) => !!m.defaultFineTunedModelId)
                  .map((model) => ({
                    label: model.name,
                    value: model.id,
                  }))}
                renderItem={(item) => item.label}
                onChange={() => setValue("llmFineTunedModelId", "")}
                disabled={isLoading}
                fullWidth
              />
            </Stack>
            <Stack flex={1}>
              {isLoadingVersions && <CircularProgress />}
              {!isLoadingVersions && !!versions.length && (
                <ControlledSelect
                  control={control}
                  label="Version"
                  name="llmFineTunedModelId"
                  emptyLabel="Default Version"
                  items={versions
                    .filter((v) => !!v.fineTunedModelId)
                    .map((version) => ({
                      label: toLocaleDateTimeString(version.createdAt),
                      value: version.fineTunedModelId || "",
                    }))}
                  renderItem={(item) => item.label}
                  disabled={isLoading || isLoadingVersions || !llmModelId}
                  fullWidth
                />
              )}
            </Stack>
          </Stack>
        )}
        <ControlledSwitch
          control={control}
          label="Has Custom Chat Prompt"
          name="hasCustomChatPrompt"
          disabled={isLoading}
        />
        {hasCustomChatPrompt && (
          <ControlledTextField
            control={control}
            label="Chat Prompt"
            name="chatPrompt"
            autoComplete="off"
            disabled={isLoading}
            minRows={3}
            multiline
          />
        )}
        {!hasCustomChatPrompt && (
          <>
            <ControlledSelect
              control={control}
              name="purpose"
              label="Purpose"
              items={TALENT_PURPOSES.map((purpose) => ({
                label: TALENT_PURPOSE_RECORD[purpose].label,
                value: purpose,
              }))}
              renderItem={(item) => item.label}
              disabled={isLoading}
              fullWidth
            />
            <ControlledSelect
              control={control}
              name="personalityTraits"
              label="Personality Traits"
              items={TALENT_PERSONALITY_TRAITS.map((trait) => ({
                label: TALENT_PERSONALITY_TRAIT_RECORD[trait].label,
                value: trait,
              }))}
              renderItem={(item) => item.label}
              disabled={isLoading}
              multiple
              fullWidth
            />
            <ControlledSelect
              control={control}
              name="interests"
              label="Interests"
              items={TALENT_INTERESTS.map((interest) => ({
                label: `${TALENT_INTEREST_RECORD[interest].label}${
                  CURRENT_TALENT_INTERESTS.includes(
                    interest as CurrentTalentInterest
                  )
                    ? ""
                    : " (deprecated)"
                }`,
                value: interest,
              }))}
              renderItem={(item) => item.label}
              disabled={isLoading}
              multiple
              fullWidth
            />
          </>
        )}
        <ControlledTextField
          control={control}
          label="Facts"
          name="facts"
          autoComplete="off"
          disabled={isLoading}
          minRows={3}
          multiline
        />
      </Stack>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">Chat Examples</Typography>
        {llmType !== "gpt-3.5-fine-tuned" && (
          <ControlledTextField
            control={control}
            label="Amount of Chat Examples within the Chat Prompt"
            name="chatExampleCount"
            autoComplete="off"
            type="number"
            disabled={isLoading}
            fullWidth
          />
        )}
        <Stack spacing={1.5}>
          {chatExamples.fields.map((field, index) => (
            <Stack key={field.id} spacing={1.5} direction="row">
              <ControlledTextField
                control={control}
                label="Request"
                name={`chatExamples.${index}.requestText`}
                autoComplete="off"
                disabled={isLoading}
                fullWidth
              />
              <ControlledTextField
                control={control}
                label="Response"
                name={`chatExamples.${index}.responseText`}
                autoComplete="off"
                disabled={isLoading}
                fullWidth
              />
              <Stack justifyContent="center">
                <IconButton
                  size="large"
                  onClick={() => chatExamples.remove(index)}
                  disabled={isLoading}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Stack>
            </Stack>
          ))}
          <Button
            onClick={() =>
              chatExamples.append({ requestText: "", responseText: "" })
            }
            sx={{ alignSelf: "flex-start" }}
            disabled={isLoading}
          >
            Add Chat Example
          </Button>
        </Stack>
      </Stack>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">Welcome Messages</Typography>
        <Stack spacing={1.5}>
          {initialChatMessages.fields.map((field, index) => (
            <Fragment key={field.id}>
              <Stack spacing={1.5} direction="row">
                <ControlledTextField
                  control={control}
                  label="Message"
                  name={`initialChatMessages.${index}.text`}
                  autoComplete="off"
                  disabled={isLoading}
                  fullWidth
                />
                <IconButton
                  size="large"
                  onClick={() => initialChatMessages.remove(index)}
                  disabled={isLoading}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Stack>
              <AttachmentFormFields
                control={control}
                index={index}
                isLoading={isLoading}
                talentId={talent.id}
              />
            </Fragment>
          ))}
          <Button
            onClick={() =>
              initialChatMessages.append({
                id: v4(),
                text: "",
              })
            }
            sx={{ alignSelf: "flex-start" }}
            disabled={isLoading}
          >
            Add Initial Chat Message
          </Button>
        </Stack>
      </Stack>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">Suggestions</Typography>
        <Stack spacing={1.5}>
          {chatMessageSuggestions.fields.map((field, index) => (
            <Fragment key={field.id}>
              <Stack spacing={1.5} direction="row">
                <ControlledTextField
                  control={control}
                  label="Message"
                  name={`chatMessageSuggestions.${index}.text`}
                  autoComplete="off"
                  disabled={isLoading}
                  fullWidth
                />
                <IconButton
                  size="large"
                  onClick={() => chatMessageSuggestions.remove(index)}
                  disabled={isLoading}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Stack>
            </Fragment>
          ))}
          <Button
            onClick={() =>
              chatMessageSuggestions.append({ id: v4(), text: "" })
            }
            sx={{ alignSelf: "flex-start" }}
            disabled={isLoading}
          >
            Add Suggestion
          </Button>
        </Stack>
      </Stack>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">Static Attachments</Typography>
        <Stack spacing={1.5}>
          {staticAttachments.fields.map((field, index) => (
            <Stack key={field.id} direction="row" spacing={1.5}>
              <FilePreview filePath={field.filePath} type={field.type} />

              <ControlledTextField
                control={control}
                name={`staticAttachments.${index}.context`}
                label="Context"
                autoComplete="off"
                disabled={isLoading}
                fullWidth
              />

              <Stack justifyContent="center">
                <IconButton
                  size="large"
                  onClick={() => staticAttachments.remove(index)}
                  disabled={isLoading}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Stack>
            </Stack>
          ))}
          <FileInput
            disabled={isUploadingFiles}
            onSelect={uploadFiles}
            accept="image/jpg,image/jpeg,image/png,image/webp,audio/mp3"
            multiple
          >
            <Button variant="text" disabled={isUploadingFiles}>
              {isUploadingFiles && "Uploading... "}
              {!isUploadingFiles && "Add Static Attachments"}
            </Button>
          </FileInput>
        </Stack>
      </Stack>
      <Button
        size="large"
        type="submit"
        variant="contained"
        sx={{ alignSelf: "flex-start" }}
        disabled={isLoading}
      >
        Save
      </Button>
    </Stack>
  );
};
