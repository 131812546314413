import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { ErrorView } from "../../Common/views/ErrorView";
import { LoadingView } from "../../Common/views/LoadingView";
import { useAppConfig } from "../hooks/useAppConfig";
import { AppConfigForm } from "./AppConfigForm";

export const AppConfigPage: FC = () => {
  const [appConfig, isLoading, error] = useAppConfig("default");

  return (
    <Stack p={3} spacing={3}>
      <Typography variant="ah2">App</Typography>
      {isLoading && <LoadingView />}
      {error && <ErrorView error={error} />}
      {appConfig && <AppConfigForm appConfig={appConfig} />}
    </Stack>
  );
};
