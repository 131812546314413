import { Divider, Skeleton, Stack, Typography } from "@mui/material";
import { EarningFrame, TalentConfig } from "@zall-bot/types";
import { FC, useMemo } from "react";
import { DEFAULT_APP_CONFIG } from "../../AppConfig/consts/DEFAULT_APP_CONFIG";
import { toLocaleMoney } from "../../Common/helpers/toLocaleMoney";
import { toLocalePercent } from "../../Common/helpers/toLocalePercent";
import { toTotalEarnings } from "../helpers/toTotalEarnings";
import { CreatorEarningsLineChart } from "./CreatorEarningsLineChart";

interface Props {
  talentConfig: TalentConfig;
  earningFrames: EarningFrame[];
  isLoading: boolean;
}

export const CreatorEarningsView: FC<Props> = (props) => {
  const { talentConfig, earningFrames, isLoading } = props;
  const earnings = useMemo(
    () => toTotalEarnings(earningFrames),
    [earningFrames]
  );

  return (
    <Stack spacing={2}>
      <Typography variant="h5" color="text.secondary">
        You keep{" "}
        {toLocalePercent(
          talentConfig.revenueShare || DEFAULT_APP_CONFIG.revenueShare
        )}{" "}
        commission from your AI’s earnings.
      </Typography>
      <CreatorEarningsLineChart earningFrames={earningFrames} />
      <Stack>
        <Typography variant="ah6" color="text.secondary">
          You’ve earned
        </Typography>
        <Typography variant="ah3" color="success.main">
          {isLoading && <Skeleton width={100} />}
          {!isLoading && toLocaleMoney({ amount: earnings.totalNetAmount })}
        </Typography>
        <Typography variant="ah6" color="text.secondary">
          {isLoading && <Skeleton width={75} />}
          {!isLoading && (
            <>Gross: {toLocaleMoney({ amount: earnings.totalGrossAmount })}</>
          )}
        </Typography>
      </Stack>
      <Divider />
      <Stack>
        <Typography variant="ah6" color="text.secondary">
          💬 Messages
        </Typography>
        <Typography variant="ah3">
          {isLoading && <Skeleton width={100} />}
          {!isLoading &&
            toLocaleMoney({ amount: earnings.sendMessageNetAmount })}
        </Typography>
        <Typography variant="ah6" color="text.secondary">
          {isLoading && <Skeleton width={75} />}
          {!isLoading && (
            <>
              Gross:{" "}
              {toLocaleMoney({ amount: earnings.sendMessageGrossAmount })}
            </>
          )}
        </Typography>
      </Stack>
      <Divider />
      <Stack>
        <Typography variant="ah6" color="text.secondary">
          🤳 Images
        </Typography>
        <Typography variant="ah3">
          {isLoading && <Skeleton width={100} />}
          {!isLoading &&
            toLocaleMoney({ amount: earnings.unlockImageNetAmount })}
        </Typography>
        <Typography variant="ah6" color="text.secondary">
          {isLoading && <Skeleton width={75} />}
          {!isLoading && (
            <>
              Gross:{" "}
              {toLocaleMoney({ amount: earnings.unlockImageGrossAmount })}
            </>
          )}
        </Typography>
      </Stack>
      <Divider />
      <Stack>
        <Typography variant="ah6" color="text.secondary">
          🎤 Voice notes
        </Typography>
        <Typography variant="ah3">
          {isLoading && <Skeleton width={100} />}
          {!isLoading &&
            toLocaleMoney({ amount: earnings.unlockAudioNetAmount })}
        </Typography>
        <Typography variant="ah6" color="text.secondary">
          {isLoading && <Skeleton width={75} />}
          {!isLoading && (
            <>
              Gross:{" "}
              {toLocaleMoney({ amount: earnings.unlockAudioGrossAmount })}
            </>
          )}
        </Typography>
      </Stack>
      <Divider />
      <Stack>
        <Typography variant="ah6" color="text.secondary">
          🤝 Referrals
        </Typography>
        <Typography variant="ah3">
          {isLoading && <Skeleton width={100} />}
          {!isLoading && toLocaleMoney({ amount: earnings.referralNetAmount })}
        </Typography>
      </Stack>
    </Stack>
  );
};
