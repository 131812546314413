import { Avatar, Button, Chip, Stack } from "@mui/material";
import { FC, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { usePrevious } from "../../Common/hooks/usePrevious";
import { useStorageFile } from "../../Common/hooks/useStorageFile";
import { MainContainer } from "../../Main/views/MainContainer";
import { useTalent } from "../../Talent/hooks/useTalent";
import { TalentChip } from "../../Talent/views/TalentForm/TalentChip";
import { useChat } from "../hooks/useChat";
import { useChatMessagesByChatId } from "../hooks/useChatMessagesByChatId";
import { ChatMessageView } from "./ChatMessageView";

const AVATAR_SIZE = 32;

export const ChatPage: FC = () => {
  const { chatId } = useParams<"chatId">();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [chat] = useChat(chatId);
  const [talent] = useTalent(chat?.talentId);
  const profilePicture = useStorageFile(talent?.profilePictureFilePath);
  const [chatMessages, , , controller] = useChatMessagesByChatId(chatId);
  const currentLength = chatMessages.length;
  const previousLength = usePrevious(currentLength);

  useEffect(() => {
    if (!scrollRef.current) return;
    if (currentLength === previousLength) return;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    scrollRef.current.scrollIntoView({
      behavior: previousLength ? "smooth" : "instant",
    });
  }, [currentLength, previousLength]);

  if (!talent) return null;
  if (!chat) return null;

  return (
    <>
      <Stack
        p={2}
        bgcolor="background.paper"
        direction="row"
        spacing={1}
        position="sticky"
        top={0}
        zIndex={1}
      >
        <Avatar
          src={profilePicture.url}
          sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}
        />
        <TalentChip talentId={talent?.id} />
        <Chip label={`Messages: ${chat.chatMessageCount || 0}`} />
      </Stack>
      <MainContainer>
        <Stack p={3} spacing={3} flex={1}>
          {controller.hasMore && (
            <Stack mx={2} my={1}>
              <Button onClick={controller.loadMore}>Load more</Button>
            </Stack>
          )}
          {chatMessages
            .sort((a, b) => a.createdAt.toMillis() - b.createdAt.toMillis())
            .map((chatMessage) => (
              <ChatMessageView
                key={chatMessage.id}
                chatId={chat?.id}
                chatMessage={chatMessage}
              />
            ))}
          <Stack ref={scrollRef} />
        </Stack>
      </MainContainer>
    </>
  );
};
