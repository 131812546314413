import { isChat } from "@zall-bot/types";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { useValidDocumentData } from "../../Firebase/hooks/useValidDocumentData";

export function useChat(chatId?: string) {
  return useValidDocumentData(
    chatId ? getDocRef("chats", chatId) : null,
    isChat
  );
}
