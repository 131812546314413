import { Divider, Stack, TextField } from "@mui/material";
import { ChatPrompt, ChatPromptMessageType } from "@zall-bot/types";
import { FC } from "react";

interface Props {
  chatPrompt: ChatPrompt;
}

export const ChatPromptDialogTabInput: FC<Props> = (props) => {
  const { chatPrompt } = props;

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <TextField
          value={
            chatPrompt.llmFineTunedModelId || chatPrompt.llmType || "gpt-3.5"
          }
          label="LLM Model"
          sx={{ textarea: { fontSize: 14 } }}
          fullWidth
        />
        <TextField
          value={chatPrompt.llmTemperature || "1"}
          label="LLM Temperature"
          sx={{ textarea: { fontSize: 14 } }}
          fullWidth
        />
      </Stack>
      <Divider>Messages</Divider>
      {!!chatPrompt.messages &&
        chatPrompt.messages.map((message, index) => (
          <TextField
            key={index}
            value={message.text}
            label={toOpenAiLabel(message.type)}
            sx={{ textarea: { fontSize: 14 } }}
            fullWidth
            multiline
          />
        ))}
      <Divider>Functions</Divider>
      {chatPrompt.functions?.map((functionSchema, index) => (
        <TextField
          key={index}
          value={JSON.stringify(functionSchema, null, 4)}
          label={`Function: ${functionSchema.name}`}
          sx={{ textarea: { fontSize: 14 } }}
          fullWidth
          multiline
        />
      ))}
    </Stack>
  );
};

function toOpenAiLabel(type: ChatPromptMessageType) {
  switch (type) {
    case "SYSTEM":
      return "System";
    case "USER":
      return "Human";
    case "TALENT":
      return "AI";
  }
}
