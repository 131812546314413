import { isUser } from "@zall-bot/types";
import { query, where } from "firebase/firestore";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { useValidCollectionData } from "../../Firebase/hooks/useValidCollectionData";

export function useTalentUsers(talentId?: string) {
  return useValidCollectionData(
    talentId
      ? query(
          getCollectionRef("users"),
          where("managedTalentIds", "array-contains", talentId)
        )
      : null,
    isUser
  );
}
