import { Button, Paper, Stack, Typography } from "@mui/material";
import { Talent } from "@zall-bot/types";
import { FC, useState } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { ErrorView } from "../../Common/views/ErrorView";
import { LoadingView } from "../../Common/views/LoadingView";
import { createModelCallable } from "../../Model/callables/createModelCallable";
import { useModels } from "../../Model/hooks/useModels";
import { ModelForm } from "../../Model/views/ModelForm";

interface Props {
  talent: Talent;
}

export const TalentModelView: FC<Props> = (props) => {
  const { talent } = props;
  const [models, isLoading, error] = useModels([talent.id]);
  const [isCreatingModel, setIsCreatingModel] = useState(false);

  const isLoaded = !isLoading && !error;

  return (
    <Stack spacing={3}>
      {isLoading && <LoadingView />}
      {error && <ErrorView error={error} />}
      {isLoaded && (
        <>
          {!!models.length && <ModelForm model={models[0]} talent={talent} />}
          {!models.length && (
            <Stack spacing={3} p={2} component={Paper}>
              <Typography variant="ah3">Create Model</Typography>
              <Button
                size="large"
                variant="contained"
                onClick={async () => {
                  try {
                    setIsCreatingModel(true);
                    await createModelCallable({
                      name: talent.name,
                      talentId: talent.id,
                    });
                  } catch (error) {
                    handleError(error);
                  } finally {
                    setIsCreatingModel(false);
                  }
                }}
                sx={{ alignSelf: "flex-start" }}
                disabled={isCreatingModel}
              >
                Create Model for {talent.name}
              </Button>
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
};
