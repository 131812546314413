import { isChatPrompt } from "@zall-bot/types";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { useValidDocumentData } from "../../Firebase/hooks/useValidDocumentData";

export function useChatPrompt(chatId?: string, chatMessageId?: string) {
  return useValidDocumentData(
    chatId && chatMessageId
      ? getDocRef(`chats/${chatId}/prompts`, chatMessageId)
      : null,
    isChatPrompt
  );
}
